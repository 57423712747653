import React, { useContext } from 'react';
import flush from 'just-flush';
import { NumberedInfoCardsGrid } from '@stereoagency/ui';
import { useCallToActions } from '@/hooks';
import { LinesContext } from '@/components/LinesContext';
import { parseHtml } from '@/utils';

type CardEntry = {
  card_title?: string;
  card_description?: string;
  card_cta?: number;
};

type Props = {
  data: {
    label?: string;
    title?: string;
    cards?: CardEntry[];
  }
};

export default function NvisoNumberedInfoCardsGrid({ data }: Props) {
  const { enableLines } = useContext(LinesContext);
  const callToActionIds = Array.from(data.cards?.reduce<Set<number>>((acc, card) => {
    if (typeof card.card_cta === 'number') { acc.add(card.card_cta); }
    return acc;
  }, new Set()) ?? new Set<number>());
  const callToActionObjects = useCallToActions(callToActionIds, true);
  const entries = flush(data.cards?.map((card, cardIndex) => {
    const cta = typeof card.card_cta === 'number' ? callToActionObjects.get(card.card_cta) : null;
    if (!card.card_title) return null;
    return {
      number: cardIndex + 1,
      title: card.card_title,
      description: card.card_description ? parseHtml(card.card_description) : null,
      callToActions: flush([cta]),
    };
  }) ?? []);

  if (!data.title) {
    return null;
  }

  return (
    <NumberedInfoCardsGrid
      label={data.label}
      title={data.title}
      cards={entries}
      enableLines={enableLines}
    />
  );
}
